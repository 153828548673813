.hero-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    padding: 20px;
  }
  
  .hero-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    max-width: 1200px;
  }
  
  .text-section {
    flex: 1;
  }
  
  .text-section h2 {
    font-size: 2rem;
    margin: 0;
  }
  
  .text-section h3 {
    font-size: 1.5rem;
    margin: 10px 0;
  }
  
  .text-section p {
    font-size: 1.3rem;
    color: #555;
  }
  
  .image-section {
    flex: 1;
    display: flex;
    justify-content: center;
  }
  
  .circle-image {
    border-radius: 50%;
    width: 200px;
    height: 200px;
    object-fit: cover;
  }
  
  @media (max-width: 768px) {
    .hero-item {
      flex-direction: column;
      text-align: center;
    }
  
    .image-section {
      margin-top: 20px;
    }
  }
  