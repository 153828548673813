.instruction-container {
    max-width: 600px;
    margin: 40px auto;
    padding: 30px;
    background: #ffffff;
    border-radius: 12px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    text-align: left; /* ✅ Aligns all text to the left */
  }
  
  .instruction-title {
    font-size: 24px;
    color: #4748ac;
    margin-bottom: 10px;
    font-weight: bold;
    text-align: center; /* ✅ Title stays center-aligned */
  }
  
  .instruction-text {
    font-size: 16px;
    color: #333;
    margin-bottom: 12px;
    text-align: left; /* ✅ Left-aligns intro text */
  }
  
  .divider {
    margin: 20px 0;
    border: none;
    border-top: 2px solid #ddd;
  }
  
  .instruction-subtitle {
    font-size: 18px;
    color: #333;
    margin-bottom: 20px;
    font-weight: bold;
  }
  
  .instruction-list {
    text-align: left;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  
  .instruction-item {
    display: flex;
    align-items: center;
    background: #f8f9fa;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .icon {
    color: #28a745;
    font-size: 18px;
    margin-right: 10px;
  }
  
  .start-btn {
    padding: 12px 25px;
    border: none;
    border-radius: 8px;
    background-color: #28a745;
    color: white;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out, transform 0.2s;
    margin-top: 20px;
    display: block;
    text-align: center;
    width: 100%;
  }
  
  .start-btn {
    padding: 12px 25px;
    border: none;
    border-radius: 8px;
    background-color: #28a745;
    color: white;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out, transform 0.2s;
    margin-top: 20px;
    display: block;
    text-align: center;
    width: 200px; /* ✅ Reduced width */
    margin-left: auto; /* ✅ Centers the button */
    margin-right: auto; /* ✅ Centers the button */
  }
  
  .start-btn:hover {
    background-color: #218838;
    transform: scale(1.05);
  }
  .set-selection {
    text-align: center;
    margin-top: 20px;
  }
  
  .set-selection h3 {
    font-size: 18px;
    margin-bottom: 15px;
  }
  
  .set-btn {
    background-color: #0056b3;
    color: white;
    border: none;
    padding: 10px 15px;
    margin: 5px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    transition: background 0.3s ease-in-out;
  }
  
  .set-btn:hover {
    background-color: #003f7f;
  }
  
  .set-btn.selected {
    background-color: #28a745; /* Green for selected set */
  }
  
  .start-btn {
    background-color: #ff9800;
    color: white;
    border: none;
    padding: 12px 20px;
    margin-top: 20px;
    font-size: 18px;
    cursor: pointer;
    border-radius: 5px;
    transition: background 0.3s ease-in-out;
  }
  
  .start-btn:hover {
    background-color: #e68900;
  }
  
  
  
  .refresh-icon {
    color: #007bff;
    margin-right: 8px;
    
  }
  